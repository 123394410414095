import axios from "axios";

const ApiService = {
  baseUrl: "https://gamefluence.net:1337/api/v1",
  korekBaseUrl: "https://korek-he.trendy-technologies.com",

  setHeader() {
    axios.defaults.headers.common["lang"] =
      localStorage.getItem("gamefluence_lang") ?? "en";
  },

  async generateScript(phone, uuid, timestamp) {
    delete axios.defaults.headers.common["lang"];
    try {
      const response = await axios.get(
        this.korekBaseUrl +
          "/dcbprotect.php?action=script&ti=" +
          uuid +
          "&ts=" +
          timestamp +
          "&te=%23cta_button&servicename=gamefluenec&merchantname=Tornet"
      );

      return response;
    } catch (e) {
      return e.response;
    }
  },

  async login(phoneNumber, ti) {
    axios.defaults.headers.common["lang"] =
      localStorage.getItem("gamefluence_lang") ?? "en";
    let countryCode =
      localStorage.getItem("countryCode") === "964" ? "korek" : "etisalat";
    let phoneNumberKey =
      localStorage.getItem("countryCode") === "971" ? "msisdn" : "phoneNumber";
    const tracking_id = localStorage.getItem("tracking_id");

    try {
      const response = await axios.post(
        this.baseUrl + `/auth/${countryCode}/subscribe`,
        {
          [phoneNumberKey]: phoneNumber,
          ti: ti,
          tracking_id,
        }
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },

  async verify(phoneNumber, otp, ts, ti, referenceId) {
    const isIraqi = localStorage.getItem("countryCode") === "964";

    let countryCode = isIraqi ? "korek" : "etisalat";
    const tracking_id = localStorage.getItem("tracking_id");

    try {
      const phoneNumberKey = isIraqi ? "phoneNumber" : "msisdn";
      const payload = {
        [phoneNumberKey]: phoneNumber,
        pincode: otp,
        ts: ts,
        ti: ti,
        tracking_id,
      };
      if (!isIraqi) {
        payload.referenceId = referenceId;
      }
      const response = await axios.post(
        this.baseUrl + `/auth/${countryCode}/verify`,
        payload
      );
      return response;
    } catch (e) {
      return e.response;
    }
  },

  async checkUserStatus(phoneNumber) {
    try {
      const response = await axios.post(this.baseUrl + "/auth/userStatus", {
        phoneNumber: phoneNumber,
        country_code: localStorage.getItem("countryCode"),
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },

  async refreshToken(id) {
    return axios
      .get(
        `https://gamefluence.net:1337/api/v1/auth/etisalat/refreshToken/${id}`
      )
      .then((response) => {
        if (response.data.data && response.data.code === 22) {
          if (response.data.data !== "" && response.data.data.game_pix_token) {
            // localStorage.setItem(
            //   "fitVibToken",
            //   response.data.data.game_pix_token
            // );

            this.headers = {
              Authorization: "Bearer " + response.data.data.game_pix_token,
              lang: localStorage.getItem("lang"),
            };

            window.location.href = "/msisdn-login";
          }
        }
      });
  },
};

export default ApiService;
