<template>
  <section id="login">
    <loading v-model:active="isLoading" color="#e03339" is-full-page="true" />
    <div
      style="
        text-align: end;
        color: #fff;
        align-items: end !important;
        margin-right: 15px;
        width: 100%;
      "
    >
      <div></div>
      <div v-if="$i18n.locale === 'en'" @click="changeLang('ar')">عربي</div>
      <div v-if="$i18n.locale === 'ar'" @click="changeLang('en')">English</div>
    </div>
    <div style="color: white">
      <div style="font-size: 12px">{{ $t("login.header") }}</div>
    </div>
    <div id="logo">
      <img src="../assets/log.png" alt="" />
    </div>

    <!--    <div id="info">-->
    <!--      <img src="../assets/lock.png" alt="">-->
    <!--      <p>{{ $t("helloMsg") }}</p>-->
    <!--    </div>-->

    <div class="tel">
      <label for="phone" id="phonelabel"> {{ $t("login.otp") }}</label>

      <div id="otp">
        <input
          v-model="pin1"
          type="text"
          v-on:keyup="$event.target.nextElementSibling.focus()"
          maxlength="1"
        />
        <input
          v-model="pin2"
          type="text"
          v-on:keyup="$event.target.nextElementSibling.focus()"
          maxlength="1"
        />
        <input
          v-model="pin3"
          type="text"
          v-on:keyup="$event.target.nextElementSibling.focus()"
          maxlength="1"
        />
        <input v-model="pin4" type="text" maxlength="1" />
        <!-- <input
          v-model="pin5"
          type="text"
          maxlength="1"
          v-if="getCountryCode === '971'"
        /> -->
      </div>

      <div style="display: flex; justify-content: space-between">
        <button
          class="sendBtn"
          :disabled="disabled"
          id="cta_button"
          @click="verify()"
        >
          {{ $t("login.subscribe") }}
        </button>
        <button
          class="existBtn"
          id="cta_button_Exist"
          @click="redirectToGoogle"
        >
          {{ $t("login.exit") }}
        </button>
      </div>
    </div>
    <div style="font-size: 14px; margin: 10px; color: white">
      {{ $t("login.header") }}
    </div>
    <div style="font-size: 10px; margin: 8px; color: white">
      {{ $t("login.otpTrial") }}
    </div>

    <div
      style="text-anchor: middle; font-size: 0.6rem; margin: 10px; color: #fff"
      :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
    >
      {{ $t("login.subTerms") }}
    </div>
    <!-- <ul
      style="font-size: 0.6rem; color: #fff; margin: 10px"
      :class="$i18n.locale === 'ar' ? 'dir-rtl' : 'dir-ltr '"
    >
      <li v-for="i in 8" :key="i" class="m-0">
        {{ $t(`login.term${i}`) }}
      </li>
      <li>
        {{ $t(`login.term10`) }}
        <span
          @click="showTerms"
          style="text-decoration: underline; color: #e03339"
          >{{ $t("login.clickHere") }}</span
        >
      </li>
    </ul> -->

    <ul
      v-if="$i18n.locale === 'ar'"
      style="
        font-size: 0.6rem;
        color: #fff;
        margin: 10px;
        direction: rtl;
        text-align: start;
      "
    >
      <li>أهلاً بكِ في بوابة Gamefluence. تقدم هذه الخدمة للمستخدم</li>
      <li>
        مجانًا لمدة 24 ساعة بعد ذلك، سيتم تلقائيًا تحصيل 2.10 درهما يوميًا.
      </li>
      <li>
        لا يوجد التزام، يمكنك إلغاء الاشتراك في أي وقت عن طريق إرسال C GAF إلى
        1111
      </li>
      <li>للمساعدة، يرجى التواصل مع support@mt2morrow.com</li>
      <li>التجربة المجانية متاحة لمشتركي اتصالات الجدد فقط</li>
      <li>استمتع بتجربتك المجانية لمدة 24 ساعة</li>
      <li>
        يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن
        لديك اتصال إنترنت آمن للوصول السريع إلى المحتوى
      </li>
      <li>تقنيات الحظر ولديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.</li>
      <li>
        لكي تستطيع استخدام هذه الخدمة يجب عليك أن تكون فوق الـ18 عامًا أو يكون
        لديك إذن من عائلتك أو من الشخص المفوض بدفع فاتورة هاتفك. الأسعار تشمل
        ضريبة القيمة المضافة بنسبة 5%
      </li>
      <li>
        للحصول على كامل الشروط والأحكام
        <span
          style="text-decoration: underline; color: #e03339"
          @click="showTerms"
          >{{ $t("login.clickHere") }}</span
        >
      </li>
    </ul>
    <ul
      v-else
      style="font-size: 0.6rem; color: #fff; margin: 10px; text-align: start"
    >
      <li>Welcome to the Gamefluence portal. This service offers the user</li>
      <li>
        Free for 24 hours then, you will be charged AED 2.10/day automatically.
      </li>
      <li>No commitment, you can cancel any time by sending C GAF to 1111.</li>
      <li>For support, please contact us at support@mt2morow.com</li>
      <li>Free trial applicable only for first time subscriber.</li>
      <li>Enjoy your Free trial for 24 hours.</li>
      <li>Please make sure that your browser is not using any 3rd-party.</li>
      <li>
        Blocking technologies and you have a healthy internet connection for
        swift access to the content.
      </li>
      <li>
        To make use of this service, you must be 18 or more unless you have
        received permission from your parents or the person who is authorized to
        pay your bill. Prices are subject to 5% VAT
      </li>
      <li>
        For full terms and condition
        <span
          style="text-decoration: underline; color: #e03339"
          @click="showTerms"
          >{{ $t("login.clickHere") }}</span
        >
      </li>
    </ul>
  </section>
</template>

<script>
import { mask } from "vue-the-mask";

import ApiService from "@/services/api.service";
import notify from "../mixins/notifyMessage.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
export default {
  name: "verifyPage",
  directives: { mask },
  mixins: [notify],
  components: {
    Loading,
  },
  data() {
    return {
      phoneNumber: "",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      // pin5: "",
      checkUserStatusInterval: "",
      isLoading: false,
      disabled: false,
    };
  },
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("gamefluence_lang", lang);
      this.$http.defaults.headers.common["lang"] =
        localStorage.getItem("gamefluence_lang");
    },
    async verify() {
      this.disabled = true;
      this.isLoading = true;
      let otp = this.pin1 + this.pin2 + this.pin3 + this.pin4;
      // if (this.getCountryCode === "971") {
      //   otp += this.pin5;
      // }
      let phoneNumber = this.$store.state.phoneNumber;
      let ts = this.$store.state.ts;
      let ti = this.$store.state.ti;

      const referenceId = localStorage.getItem("referenceId");
      const response = await ApiService.verify(
        phoneNumber,
        otp,
        ts,
        ti,
        referenceId
      );

      this.isLoading = false;

      if (response && response.data.status == "success") {
        await ApiService.checkUserStatus(phoneNumber).then((response) => {
          if (response.data.status === 2 || response.data.status === 1) {
            if (this.getCountryCode === "971") {
              this.$router.push(`/thank-you/${response.data.data}`);
            } else
              window.location.href =
                "https://gamefluence.hub.gamepix.com/?token=" +
                response.data.data;
          } else {
            this.errorMessage(response.data["message"]);
          }
        });

        this.disabled = false;
      } else {
        this.errorMessage(response.data["message"]);
        this.disabled = false;
      }
    },
    showTerms() {
      let enText = `<div style="font-size: 14px;text-align: start">
<h3>Service Terms and Conditions</h3>
          <strong>1. Free Trial Period</strong>

          Upon subscribing to the service, you will receive a 24-hour free trial period. During this time, you can access all the features and benefits of the service without any charge.
          <br/>
          <strong>2. Subscription Fee</strong>

          After the 24-hour free trial period ends, a daily fee of AED 2.10 (VAT included) will be automatically charged to your account.
          This fee will be deducted every day to maintain your subscription and access to the service.
          <br/>
          <strong>3. Automatic Renewal</strong>

          The subscription service is designed to renew automatically after the initial free trial period.
          This means that after the first 24 hours, the daily charge of AED 2.10 will continue to be applied without any further action required from your side.
          <br/>
          <strong>4. No Commitment</strong>

          The service offers flexibility with no long-term commitment required.
          You have the freedom to cancel the subscription at any time without any penalties or additional charges.
          <br/>
          <strong>5. Cancellation Process</strong>

          If you wish to cancel your subscription, you can do so easily.
          To cancel, simply send a text message with the code "C GAF" to the number 1111.
          Upon sending this message, your subscription will be terminated, and you will no longer be charged the daily fee.
        </div>`;
      let arText = `<div style="font-size: 14px;text-align: start;direction: rtl">
<h3>شروط وأحكام الخدمة</h3>
          <strong>1. فترة التجربة المجانية</strong>

عند الاشتراك في الخدمة، ستحصل على فترة تجربة مجانية لمدة 24 ساعة. وخلال هذه الفترة، يمكنك الوصول إلى جميع ميزات وفوائد الخدمة دون أي رسوم.

          <br/>
          <strong>2. تكلفة الاشتراك</strong>

بعد انتهاء فترة التجربة المجانية لمدة 24 ساعة، سيتم خصم تكلفة يومية بقيمة 2.10 درهم إماراتي (بما في ذلك ضريبة القيمة المضافة) تلقائيًا على حسابك.
سيتم خصم هذه الرسوم يوميًا للحفاظ على اشتراكك والوصول إلى الخدمة.

      <br/>
          <strong>3. التجديد التلقائي</strong>

         تم تصميم خدمة الاشتراك للتجديد تلقائيًا بعد فترة التجربة المجانية الأولية.
وهذا يعني أنه بعد أول 24 ساعة، ستستمر التكلفة اليومية بقيمة 2.10 درهم إماراتي في التطبيق دون الحاجة إلى أي إجراء آخر من جانبك.

   <br/>
          <strong>4. عدم الالتزام</strong>

         تقدم الخدمة المرونة دون الحاجة إلى التزام طويل الأجل.
لديك الحرية في إلغاء الاشتراك في أي وقت دون أي عقوبات أو تكلفة إضافية.

 <br/>
          <strong>5. عملية الإلغاء</strong>

          إذا كنت ترغب في إلغاء اشتراكك، يمكنك القيام بذلك بسهولة.
لإلغاء الاشتراك، ما عليك سوى إرسال رسالة نصية بالرمز "C GAF" إلى الرقم 1111.
عند إرسال هذه الرسالة، سيتم إنهاء اشتراكك ولن يتم تحصيل الرسوم اليومية منك بعد الآن.
        </div>`;
      this.$swal(this.$i18n.locale === "en" ? enText : arText);
    },
    redirectToGoogle() {
      window.location.href = "https://www.google.com";
    },
  },
  computed: {
    getCountryCode: function () {
      return localStorage.getItem("countryCode");
    },
  },
};
</script>

<style scoped lang="scss">
#login {
  @media (max-width: 600px) {
    background-image: url("../assets/mob.jpg");
  }

  @media (min-width: 601px) {
    background-image: url("../assets/web.jpg");
  }

  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;

  #logo {
    width: 200px;
    height: 200px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  #info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: end !important;
    margin-bottom: 0.5rem;
    height: fit-content;

    @media (min-width: 600px) {
      width: 500px;
    }

    @media (max-width: 768px) {
      width: 300px;
    }

    img {
      width: 200px;
      height: 200px;

      @media (max-width: 600px) {
        width: 80px;
        height: 80px;
      }
    }

    p {
      color: #fff;
      font-size: 40px;

      @media (max-width: 600px) {
        font-size: 25px;
      }
    }
  }

  .tel {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;

    @media (min-width: 600px) {
      width: 500px;
    }

    @media (max-width: 600px) {
      width: 300px;
    }

    label {
      width: 100%;
      color: white;
      justify-content: start;
      //  text-align: left;
      font-size: 16px;
      margin-bottom: 0.5rem;
    }

    #otp {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 2rem;

      input {
        height: 50px;
        width: 50px;
        text-align: center;
        background-color: #ddd;
        font-size: 30px;
        outline: none;
        border: none;
        border-radius: 8px;

        @media (max-width: 375px) {
          height: 50px;
          width: 50px;
        }
      }
    }

    .sendBtn {
      height: 50px;
      margin-right: 10px;
      color: white;
      background-color: #e03339;
      border: none;
      border-radius: 0.5rem;
      font-size: 18px;
      cursor: pointer;
      font-weight: bold;
      width: 100%;
    }
    .existBtn {
      height: 50px;
      color: #fff;
      background: transparent;
      border: 1px solid #e03339;
      border-radius: 0.5rem;
      font-size: 18px;
      cursor: pointer;
      font-weight: bold;
      width: 100%;
    }
  }
}

.dir-ltr {
  direction: ltr;
  text-align: left;
}

.dir-rtl {
  direction: rtl;
  text-align: right;
}
</style>
