import login from "./components/login.vue";
import verify from "./components/verify.vue";
import loginEtsalat from "./components/login-etsalat.vue";
import verifyEtsalat from "./components/verify-etsalat.vue";
import MsisdnLogin from "./components/msisdn-login.vue";

import { createRouter, createWebHistory } from "vue-router";
const ThankYou = () => import("./components/ThankYouPage.vue");
const TermsAndConditions = () =>
  import("./components/terms-and-conditions.vue");

const routes = [
  {
    path: "/thank-you/:id",
    name: "thank-you",
    meta: { auth: true, name: "thank-you", slider: "false" },
    component: ThankYou,
  },
  {
    path: "/:lang?",
    name: "login",
    component: login,
  },
  {
    path: "/verify/:phoneNumber?",
    name: "verify",
    component: verify,
  },
  {
    path: "/login-etisalat/:lang?",
    name: "login-etisalat",
    component: loginEtsalat,
  },
  {
    path: "/msisdn-login",
    name: "msisdn-login",
    component: MsisdnLogin,
  },
  {
    path: "/verify-etsalat/:phoneNumber?",
    name: "verify-etisalat",
    component: verifyEtsalat,
  },
  {
    path: "/terms-and-conditions",
    name: "terms-conditions",
    component: TermsAndConditions,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const code = localStorage.getItem("countryCode");

  if (to.name === "login" && code === "971") {
    next("/login-etisalat");
  } else {
    next();
  }
});

export default router;
