const ar = {
  login: {
    phoneNumber: "أدخل رقم هاتفك لتلقي الرمز السري",
    msisdnLogin: "أدخل رقم هاتفك",
    otp: "أدخل الرمز السري الذي تلقيته عبر رسالة نصية قصيرة",
    alreadyHaveAccount: "لديك حساب؟",
    noAccount: "ليس لديك حساب؟ ",
    freeTrial:
      "مجانًا لمدة 24 ساعة ثم 2.10 درهما باليوم. السعر يشمل ضريبة القيمة المضافة",
    otpTrial: 'لتأكيد اشتراكك Pin بعد الضغط على "اشترك"، ستتلقى رسالة',
    exit: "خروج",
    loading: "تحميل",
    subscribe: "اشترك",
    header:
      "مجانًا لمدة 24 ساعة ثم 2.10 درهمًا في اليوم، شامل ضريبة القيمة المضافة",
    terms: "الأحكام والشروط ",
    subTerms:
      "بالضغط على زر الاشتراك، فإنك توافق على الشروط والأحكام التالية: ",
    term1: " أهلاً بكِ في بوابة Gamefluence. تقدم هذه الخدمة للمستخدم",
    term2: "مجانًا لمدة 24 ساعة بعد ذلك، سيتم تلقائيا تحصيل  2.10درهما يوميا.",
    term3:
      "لا يوجد إلتزام، يمكنك إلغاء الاشتراك بأي وقت عن طريق إرسال C GAF الى 1111",
    term4: "للمساعدة، يرجى التواصل مع support@mt2morrow.com",
    term5: "التجربة المجانية متاحة لمشتركي اتصالات الجدد فقط",
    term6: "استمتع بتجربتك المجانية لمدة 24 ساعة",
    term7:
      "يرجى التأكد من أن متصفحك لا يستخدم أي تقنيات حظر تابعة لجهات خارجية وأن لديك اتصال إنترنت آمن للوصول السريع إلى المحتوى",
    term8: "تقنيات الحظر ولديك اتصال إنترنت صحي للوصول السريع إلى المحتوى.",
    term9:
      "لكي تستطيع إستخدام هذه الخدمة يجب عليك أن تكون فوق ال18 عاماً أو يكون معك إذن من عائلتك أو من الشخص المفوض دفع فاتورة هاتفك. الأسعار تشمل ضريبة القيمة المضافة بنسبة 5 %",
    term10: "للحصول على كامل الشروط والأحكام ",
    clickHere: "اضغط هنا",
    login: "تسجيل الدخول",
  },
  enterYourNumner: "أدخل رقمك",
  sendSms: "إرسال رسائل نصية قصيرة",
  otpMsg: "أدخل كلمة المرور",
  subscribe: "اشترك",
  invalidPhone: "رقم الهاتف هذا غير صالح",
  verificationCode: "رمز التحقق",
  helloMsg: "مرحبًا! أهلًا وسهلًا",
  welcomeBackMsg: "أهلًا وسهلًا",
  verify: "تثبت",
  msg: "اهلا بك في Gamefluence  تكلفة الاشتراك 300 د.ع/يوم  لإلغاء الاشتراك ارسل 0 الى 3837",
};

export default ar;
