const en = {
  login: {
    phoneNumber: "Enter your phone number to receive OTP",
    msisdnLogin: "Enter your phone number to login",
    otp: "Enter the Pin Code you received by SMS",
    alreadyHaveAccount: "Already have an account? ",
    noAccount: "Don't have an account? ",
    freeTrial: "Free for 24 hours then AED 2.10/ day (VAT Inclusive)",
    exit: "Exit",
    subscribe: "Subscribe",
    loading: "Loading",
    otpTrial:
      "After clicking “Subscribe” you will receive PIN message to Confirm your subscription",
    header: "Free for 24 hours then AED 2.10 /Day, VAT Included",
    terms: "Terms and conditions",
    subTerms:
      "By clicking on the above subscribe button, you will agree to the below terms and conditions",
    term1: "Welcome to the Gamefluence portal. This service offers the user",
    term2:
      "Free for 24 hours then, you will be charged AED 2.10/day automatically.",
    term3: "No commitment, you can cancel any time by sending C GAF to 1111.",
    term4: "For support, please contact us at support@mt2morow.com",
    term5: "Free trial applicable only for first time subscriber.",
    term6: "Enjoy your Free trial for 24 hours.",
    term7: "Please make sure that your browser is not using any 3rd-party.",
    term8:
      "blocking technologies and you have a healthy internet connection for swift access to the content.",
    term9:
      "To make use of this service, you must be 18 or more unless you have received permission from your parents or the person who is authorized to pay your bill. Prices are subject to 5% VAT",
    term10: "For full terms and conditions ",
    clickHere: "click here",
    login: "Login",
  },

  enterYourNumner: "Phone Number",
  sendSms: "Send Sms",
  otpMsg: "Enter Your Otp",
  subscribe: "Subscribe",
  invalidPhone: "This Phone number invalid",
  verificationCode: "Verification code",
  helloMsg: "Hello!Welcome",
  welcomeBackMsg: "Welcome back!",
  verify: "Verify",
  msg: "Welcome to Gamefluence! You will be charged 300 IQD/day. To unsubscribe, send 0 to 3837.",
  msgNotIraq:
    "Welcome to Gamefluence, your ultimate destination for mobile gaming. For just AED 2.10/ day, you'll unlock access to over 150 games, all ad-free. Enjoy uninterrupted gaming anytime, anywhere. You can unsubscribe anytime by sending C GAF to 1111. Start your gaming adventure with Gamefluence today!",
};

export default en;
