<template>
  <section id="login">

    <loading v-model:active="isLoading" color="#e03339" is-full-page="true" />


    <div id="logo">
      <img src="../assets/log.png" alt="">
    </div>

    <div id="info">
      <img src="../assets/lock.png" alt="">
      <p>{{ $t("helloMsg") }}</p>
    </div>

    <div class="tel">
      <label for="phone" id="phonelabel">{{ $t("enterYourNumner") }}</label>
      <div id="phone">
        <div for="tel" id="telLabel">+{{getCountryCode}}</div>
        <input type="tel" v-model="phoneNumber" id="tel" placeholder="XXX XXX XXXX" v-mask="['### ### ####']" />
      </div>

      <button class="sendBtn" id="cta_button" @click="generateScript()">{{ $t("sendSms") }}</button>


    </div>

    <p class="subscribeMsg">{{ getCountryCode == '964' ?  $t("msg") : $t("msgNotIraq") }}</p>

  </section>
</template>




<script>

import ApiService from "@/services/api.service";
import { mask } from "vue-the-mask";
import notify from "../mixins/notifyMessage.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';


export default {
  name: "LoginPage",
  directives: { mask },
  mixins: [notify],
  components: {
    Loading
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    getCountryCode:  function () {
        return    localStorage.getItem('countryCode')
    }
  },
  methods: {
    async generateScript() {
      this.isLoading = true;

      if (this.phoneNumber.length != 12 && this.phoneNumber !== '123 123 4') {
        this.errorMessage(this.$t("invalidPhone"));
        this.isLoading = false;
        return;
      }
      var phone =this.getCountryCode + this.phoneNumber.replaceAll(" ", "");
      var uuid = "Game-Fluence-App-" + this.$uuid.v4();
      var timestamp = this.getTimeStamp();
      this.$store.commit("setPhoneNumber", phone);
      this.$store.commit("setTi", uuid);
      this.$store.commit("setTS", timestamp);


      if(this.getCountryCode === '964'){
        const response = await ApiService.generateScript(phone, uuid, timestamp);

        if (response && response.status == 200) {
        var s = response.data.s;

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.text = s;
        document.head.appendChild(script);
        const ev = new Event("DCBProtectRun");
        document.dispatchEvent(ev);

        this.sendSMS();
      } else {
        this.isLoading = false;
        this.errorMessage("Error");
        //       this.isLoading = false;
        //   this.errorMessage("Error");
      }}
    else  await this.sendSMS();
    },
    async sendSMS() {
      let phoneNumber = this.$store.state.phoneNumber;
      let ti = this.$store.state.ti;
      let response = await ApiService.login(phoneNumber, ti);
      this.isLoading = false;
      if(this.getCountryCode === '964') {
       if (response && response.data.status == 'success') {
        this.$router.push("/verify");
      } else {
        this.errorMessage(response?.message ?? 'Error');
      }}
      else {

        if (response.data.data && (response.data.code === 0 ||  response.data.code == 123 || response.data.code == 22)) {
          localStorage.setItem('referenceId', response?.data?.data?.referenceId)
          this.$router.push(`/verify/${phoneNumber}`)
        }
          else {
          this.errorMessage(response?.message ?? 'Error');
        }
      }
    },
    getTimeStamp() {
      return parseInt(new Date().getTime() / 1000);
    },
    changeLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("gamefluence_lang", lang);
      this.$http.defaults.headers.common["lang"] = localStorage.getItem("gamefluence_lang");
      this.isSelectLanguage = true;
    },
  },
  mounted() {
    if (this.$route.params.lang) {
      this.changeLang(this.$route.params.lang);
    } else {
      var lang=localStorage.getItem("gamefluence_lang") ?? "en";
      this.changeLang(lang);
    }
  }
}
</script>

<style scoped lang="scss">
#login {

  @media (max-width: 600px) {
    background-image: url('../assets/mob.jpg');
  }

  @media (min-width: 601px) {
    background-image: url('../assets/web.jpg');
  }

  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;

  #logo {
    width: 400px;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  #info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: end !important;
    margin-bottom: 1.5rem;
    height: fit-content;

    @media(min-width:600px) {
      width: 500px;
    }

    @media(max-width:768px) {
      width: 300px;
    }

    img {
      width: 200px;
      height: 200px;

      @media(max-width:600px) {
        width: 80px;
        height: 80px;
      }

    }

    p {
      color: #fff;
      font-size: 40px;

      @media(max-width:600px) {
        font-size: 25px;
      }
    }
  }


  .tel {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;

    @media(min-width:600px) {
      width: 500px;
    }

    @media(max-width:600px) {
      width: 300px;
    }

    label {
      width: 100%;
      color: white;
      justify-content: start;
      // text-align: left;
      font-size: 24px;
      margin-bottom: .5rem;
    }


    #phone {

      //border: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      margin-bottom: 2rem;
      scroll-padding-top: 10px;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-right: 1rem;
      padding-left: 1rem;
      background-color: one;

      @media (max-width: 425px) {
        justify-content: center;
        margin-bottom: 1rem;
      }

      #telLabel {
        color: black;
        font-size: 35px;
        font-weight: bold;
        border-right: 2px soild #aaa;

        @media (max-width: 600px) {
          font-size: 25px;
        }
      }

      input[type="tel"] {
        border: none;
        outline: none;
        height: 40px;
        color: black;
        padding-left: 0.4rem;
        font-weight: bold;
        font-size: 35px;
        width: 100%;

        @media (max-width: 600px) {
          font-size: 25px;
        }
      }

      ::placeholder {
        color: #aaa;
        font-weight: bold;
        font-size: 35px;

        @media (max-width: 600px) {
          font-size: 25px;
        }
      }
    }

    .sendBtn {
      height: 50px;
      color: white;
      background-color: #e03339;
      border: none;
      border-radius: .5rem;
      font-size: 18px;
      cursor: pointer;
      font-weight: bold;
      width: 100%;
    }
  }


  @media(max-width:600px) {
    .subscribeMsg {
      color: white;
      width: 300px;
      line-height: 1.5;
      direction: rtl;
    }
  }

  @media(min-width:600px) {
    .subscribeMsg {
      color: white;
      width: 400px;
      line-height: 1.5;
      direction: rtl;

    }
  }

}
</style>