const ku = {
  enterYourNumner: "ژمارە مۆبایلت داخڵ بکە",
  sendSms:"ناردنی نامە",
  otpMsg: "کۆد داخڵ بکە",
  subscribe:"بەشداری بکە",
  invalidPhone:"ژمارەکەت هەڵەیە",
  verificationCode:"کۆدی دڵنیایی",
  helloMsg:"سڵاو! بەخێربێیت",
  verify:"ناردن",
  msg:"بەخێربێی بۆ Gamefluence. نرخی بەشداریکردن 300  د.ع لە ڕۆژێکدا. بۆ ڕاگرتن 0 بنێرە بۆ 3837."
};

export default ku;